<template>
  <v-scale-transition>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <h3 class="headline grey--text text-xs-center">
            Dashboard: X-Force
          </h3>
        </v-flex>
        <v-flex xs12>
          <v-sheet
            class="transparent"
            height="300"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </v-scale-transition>
</template>

<script>
import security from '@/security'

export default {
  created: function () {
    const properties = security.me.getProperties()
    let url = '/xforce/schedule'

    properties.forEach(property => {
      if (property.name === 'dashboard.xforce' && property.value === 'admin') {
        url = '/xforce/administration'
      }
    })

    this.$router.push(url)
  },
}
</script>
